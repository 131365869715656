import React from 'react'
import {Container ,Box ,Typography} from '@mui/material'
const TermOfService = () => {
  return (
    <Container maxWidth='xl'>
<Box  marginTop='110px'paddingTop='50px' paddingBottom='70px'>

        <Box>
    <Typography variant='h1' sx={{fontSize:'50px' ,fontWeight:'700' ,letterSpacing:'1px'}}>Term Of Service</Typography>
    </Box>
    <Box  marginTop='65px' >
<Typography variant='h6' sx={{fontWeight:"600" ,fontSize:"25px"}}  >
AGREEMENT TO TERMS
</Typography>
<Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}  >
These Terms and Conditions establish a legally binding agreement between you, whether personally or on behalf of an entity (“you”) and QuantumParadox Studio (“we,” “us” or “our”), concerning your access to and utilization of our mobile application (the “Application”). You acknowledge that by accessing the Application, you have read, comprehended, and consented to abide by all of these Terms and Conditions. If you do not concur with all of these Terms and Conditions, you are expressly prohibited from utilizing the Application, and you must discontinue use immediately.</Typography>
    <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>Supplementary terms and conditions or documents that may be published on the Application periodically are explicitly integrated herein by reference. We retain the discretion, solely at our discretion, to amend or modify these Terms and Conditions at any time and for any purpose. We will notify you of any changes by updating the “Last updated” date of these Terms and Conditions, and you waive any entitlement to receive individual notice of each such alteration. It is your responsibility to regularly review these Terms and Conditions to remain informed of any updates. Your continued use of the Application after the date on which revised Terms are posted will subject you to, and be considered as acceptance of, the modifications in any revised Terms and Conditions.</Typography>
    <Typography variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>
The content on the Application is not intended for distribution to, or use by, any individual or entity in any jurisdiction or country where such distribution or use would violate the law or regulation or would necessitate registration requirements for us within that jurisdiction or country. Therefore, individuals who opt to access the Application from other locations do so at their own discretion and bear sole responsibility for adhering to local laws, if and when they are applicable.</Typography>
    <Typography variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>
The Application is designed for individuals who are at least 13 years old. Minors, typically under the age of 18, must obtain parental or guardian permission and supervision to utilize the Application.</Typography>
    </Box>
<Box  marginTop='40px'>
    <Typography variant='h1' sx={{fontSize:'30px' ,fontWeight:'700' }}>INTELLECTUAL PROPERTY RIGHTS</Typography>
    <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>
Unless explicitly stated otherwise, the Application constitutes our proprietary property. All elements, including source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Application (collectively referred to as the “Content”), along with the trademarks, service marks, and logos (the “Marks”) contained therein, are either owned or controlled by us or licensed to us. They are safeguarded by copyright, trademark, and various other intellectual property rights, as well as unfair competition laws, within the United States, foreign jurisdictions, and international conventions. The Content and Marks are provided on the Application "AS IS" for your personal information and use only. Unless expressly permitted in these Terms of Use, no part of the Application, Content, or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose without our explicit prior written consent.</Typography>
    <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>
If you are eligible to use the Application, you are granted a limited license to access, use, and download or print portions of the Content solely for personal, non-commercial purposes. We reserve all rights not expressly granted to you in the Application, Content, and Marks.</Typography>
</Box>
<Box  marginTop='40px'>
    <Typography variant='h1' sx={{fontSize:'30px' ,fontWeight:'700' }}>
USER REPRESENTATIONS</Typography>
    <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>
By using the Application, you affirm and guarantee that: (1) all registration details you provide are truthful, accurate, up-to-date, and complete; (2) you will maintain the accuracy of this information and promptly update it as required; (3) you possess the legal capacity and agree to abide by these Terms of Use; (4) you are at least 13 years old; (5) you are not considered a minor in your jurisdiction, or if you are a minor, you have obtained parental consent to use the Application; (6) you will not access the Application using automated or non-human methods, such as bots or scripts; (7) you will not utilize the Application for unlawful or unauthorized purposes; and (8) your use of the Application will not breach any applicable laws or regulations.</Typography>
    <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>
If you furnish any information that is false, misleading, outdated, or incomplete, we retain the authority to suspend or terminate your account and deny any present or future access to the Application (or any part thereof).</Typography>

    
</Box>
<Box  marginTop='40px'>
    <Typography variant='h1' sx={{fontSize:'30px' ,fontWeight:'700' }}>USER REGISTRATION</Typography>
    <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>
You might need to register with the Application. You consent to maintaining the confidentiality of your password and assume responsibility for all activities conducted using your account and password. We retain the right to revoke, reclaim, or modify any username you choose if we determine, at our sole discretion, that the username is inappropriate, obscene, or otherwise objectionable.</Typography>

    
</Box>
<Box  marginTop='40px'>
    <Typography variant='h1' sx={{fontSize:'30px' ,fontWeight:'700' }}>CONTRIBUTION LICENSE</Typography>
   
    <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>
By contributing your content to any section of the Application or making it accessible by linking your account to any social networking platforms, you automatically grant us an unrestricted, perpetual, irrevocable, worldwide, non-exclusive, transferable, royalty-free, fully-paid license. This license allows us to host, use, copy, reproduce, disclose, sell, publish, broadcast, store, cache, publicly perform, publicly display, reformat, translate, transmit, excerpt, and distribute your contributions (including your image and voice) for any purpose, whether commercial, advertising, or otherwise. Additionally, you grant us the right to create derivative works from or incorporate your contributions into other works, as well as to sublicense these rights to others. Such use and distribution may take place in any media format and through any media channels.</Typography>
    <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>
This license extends to all forms, media, or technologies, whether existing now or developed in the future. It encompasses our utilization of your name, company name, franchise name, if applicable, and any trademarks, service marks, trade names, logos, personal, and commercial images you furnish. You relinquish all moral rights associated with your contributions and confirm that moral rights have not been claimed in your contributions.</Typography>
    <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>We do not claim ownership of your contributions. You maintain full ownership of all your contributions and any associated intellectual property or proprietary rights. We bear no responsibility for the accuracy or representations within your contributions on the Application. You are solely accountable for your contributions and agree to release us from any liability and abstain from legal action against us concerning your contributions.</Typography>
    <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>We reserve the right, at our sole discretion, to: (1) edit, redact, or modify any contributions; (2) re-categorize contributions to better suit the Application's sections; and (3) pre-screen or remove contributions at any time and for any reason, without prior notice. We are not obligated to monitor your contributions.</Typography>
 
    
    

    
</Box>
<Box  marginTop='40px'>
    <Typography variant='h1' sx={{fontSize:'30px' ,fontWeight:'700' }}> 

GUIDELINES FOR REVIEWS</Typography>
    <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>We may offer sections on the Application for leaving reviews or ratings. When submitting a review, you must adhere to the following guidelines: (1) You must have direct experience with the individual or entity being reviewed; (2) Your reviews must refrain from containing offensive language, profanity, or abusive, racist, hateful, or discriminatory content; (3) Reviews should avoid making discriminatory references based on religion, race, gender, national origin, age, marital status, sexual orientation, or disability; (4) Reviews should not allude to illegal activities; (5) You should not have affiliations with competitors when posting negative reviews; (6) Avoid making legal conclusions; (7) Do not post false or misleading statements; and (8) Do not orchestrate campaigns to encourage others to post reviews, whether positive or negative.</Typography>
    <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>We reserve the right to accept, reject, or remove reviews at our sole discretion. We are under no obligation to screen or delete reviews, even if others find them objectionable or inaccurate. Reviews do not represent our opinions or those of our affiliates or partners, and they are not endorsed by us. We disclaim liability for any reviews or any claims, liabilities, or losses arising from them. By posting a review, you grant us a perpetual, non-exclusive, worldwide, royalty-free, fully-paid, assignable, and sublicensable right and license to reproduce, modify, translate, transmit, display, perform, and/or distribute all review-related content.</Typography>

    
</Box>
<Box  marginTop='40px'>
    <Typography variant='h1' sx={{fontSize:'30px' ,fontWeight:'700' }}>MOBILE APPLICATION LICENSE</Typography>
    <Typography variant='h1' sx={{fontSize:'30px' ,fontWeight:'700' ,marginTop:'20px' }}>Use License</Typography>
    <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>If you access the Application through a mobile application, you are granted a revocable, non-exclusive, non-transferable, limited right to install and use the mobile application on wireless electronic devices owned or controlled by you. This usage must strictly adhere to the terms outlined in this mobile application license within these Terms of Use. You are prohibited from: (1) attempting to decompile, reverse engineer, or decrypt the application; (2) making modifications, adaptations, or derivative works from the application; (3) violating any applicable laws, rules, or regulations related to your use of the application; (4) altering or obscuring any proprietary notices; (5) utilizing the application for revenue generation or commercial purposes not intended by its design; (6) distributing the application over a network allowing access by multiple devices or users simultaneously; (7) using the application to develop a competitive product or service; (8) sending automated queries or unsolicited commercial emails through the application; and (9) utilizing our proprietary information or intellectual property in the development of other applications, accessories, or devices.</Typography>
  
    
</Box>
<Box  marginTop='40px'>
    <Typography variant='h1' sx={{fontSize:'30px' ,fontWeight:'700' }}>Apple and Android Devices</Typography>
    <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>When utilizing a mobile application acquired from the Apple Store or Google Play (each an "App Distributor") to access the Application, the following terms apply:

1. You receive a non-transferable license to use the mobile application on a device utilizing Apple iOS or Android operating systems, in accordance with the App Distributor's terms of service.
2. We are responsible for providing maintenance and support services for the mobile application as outlined in the terms of this mobile application license or as required by law. The App Distributor is not obligated to provide maintenance or support.
3. In case of any failure to meet applicable warranties, you may notify the App Distributor, who may refund the purchase price per their policies. The App Distributor holds no other warranty obligations.
4. You confirm that you are not located in a U.S. embargoed country or designated as a "terrorist supporting" entity by the U.S. government and are not listed on any U.S. government prohibited or restricted parties list.
5. You must adhere to third-party terms of agreement while using the mobile application, ensuring compliance with wireless data service agreements.
6. The App Distributors are third-party beneficiaries of the mobile application license terms outlined in these Terms of Use. They have the right to enforce these terms against you as a third-party beneficiary.</Typography>
 
    
</Box>
<Box  marginTop='40px'>
    <Typography variant='h1' sx={{fontSize:'30px' ,fontWeight:'700' }}>
SOCIAL MEDIA</Typography>
    <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>As part of the Application's functionality, you can link your account with third-party service providers ("Third-Party Accounts") by: (1) providing your login information through the Application; or (2) allowing us access to your Third-Party Account as permitted by the terms governing your use of each Third-Party Account. You confirm that you can disclose your Third-Party Account login information to us and grant us access without violating any terms or imposing fees or limitations on us. By granting access to Third-Party Accounts, you acknowledge that we may access, store, and make available any content stored in your Third-Party Account ("Social Network Content") on the Application. Additionally, we may exchange additional information with your Third-Party Account as notified during the linking process. Depending on your Third-Party Account settings, personally identifiable information you post on these accounts may be accessible through your Application account. If a Third-Party Account becomes unavailable or access is terminated, Social Network Content may no longer be accessible on the Application. You can disconnect your Application account from Third-Party Accounts at any time. Your relationship with Third-Party Service Providers is governed solely by your agreements with them. We do not review Social Network Content and are not responsible for its accuracy or legality. You agree that we may access your email address book and contacts list solely to identify and inform you of contacts registered on the Application. You can deactivate the connection between the Application and your Third-Party Account by contacting us or adjusting your account settings. We will attempt to delete any information obtained from Third-Party Accounts stored on our servers, except for the username and profile picture associated with your account.</Typography>

    
</Box>
<Box  marginTop='40px'>
    <Typography variant='h1' sx={{fontSize:'30px' ,fontWeight:'700' }}>SUBMISSIONS</Typography>
    <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>You acknowledge and agree that any Submissions, including questions, comments, suggestions, ideas, feedback, or other information regarding the Application, provided by you to us are non-confidential and become our sole property. We have exclusive rights, including all intellectual property rights, and can use and disseminate these Submissions for any lawful purpose, without acknowledgment or compensation to you. You waive all moral rights to such Submissions and warrant their originality or your right to submit them. You agree not to hold us liable for any alleged infringement or misappropriation of proprietary rights in your Submissions.</Typography>

</Box>
<Box  marginTop='40px' >
    <Typography variant='h1' sx={{fontSize:'30px' ,fontWeight:'700' }}>CONTACT US</Typography>
    <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'20px' , color:'grey'}}>To address a grievance concerning the Application or to obtain additional information about using the Application, please</Typography>
    <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'10px' , color:'grey'}}>contact us at:  </Typography>
    <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'10px' , color:'grey'}}>Appcraftly private limited</Typography>
    <Typography  variant='h6' sx={{ fontSize:"20px" , marginTop:'10px' , color:'grey'}}>appcraftly@gmail.com</Typography>
    
</Box>



    </Box>
    </Container>
  )
}

export default TermOfService