import React, { useEffect, useRef } from "react";
import { Box, Grid, Typography, Container } from "@mui/material";
import "./index.css";
// import notification from "../../assests/notification-bell.png";
// import productWish from "../../assests/wish-list.png";
// import productFiltering from "../../assests/controls.png";
// import productSorting from "../../assests/download-file.png";
import Icon from "@mdi/react";
import {
  mdiBellOutline,
  mdiHeartOutline,
  mdiFilterOutline,
  mdiSortVariant,
  mdiLinkVariant,
  mdiCheckDecagramOutline,
  mdiCurrencyBtc,
  mdiSaleOutline,
  mdiCashMultiple,
  mdiShieldCheck,
  mdiStar,
  
} from "@mdi/js";
const data = [
  {
    icon: mdiBellOutline,
    title: "Push Notifications",
    desc: "Customize push notifications to alert users about promotions, discounts, and new products",
  },
  {
    icon: mdiHeartOutline,
    title: "Product Wish Lists",
    desc: "Create, manage, and share curated lists of desired products effortlessly.",
  },
  {
    icon: mdiFilterOutline,
    title: "Product Filtering options",
    desc: "Refine your search results with tailored filtering options for an optimized browsing experience.",
  },
  {
    icon: mdiSortVariant,
    title: "Product Sorting options",
    desc: "Easily organize and prioritize your products with versatile sorting options.",
  },
  {
    icon: mdiLinkVariant,
    title: "Deep Linking",
    desc: "Effortlessly guide users to specific products, promotions, or curated collections from social media, emails, or messages with a single tap",
  },
  {
    icon: mdiCheckDecagramOutline,
    title: "One-Page Checkout",
    desc: "Streamline the purchase process with a single-page checkout for seamless transactions.",
  },
  {
    icon: mdiCurrencyBtc,
    title: "Multi-currency support",
    desc: "Enable transactions in multiple currencies for enhanced global accessibility.",
  },
  {
    icon: mdiSaleOutline,
    title: "Discounts Integration",
    desc: "Seamlessly incorporate discount codes for enhanced savings and customer engagement.",
  },
  {
    icon: mdiCashMultiple,
    title: "Multiple Payment Options",
    desc: "Offer flexibility with various payment methods for convenient and secure transactions.",
  },
  {
    icon: mdiShieldCheck,
    title: "Secure Payment Gateway",
    desc: "Ensure safe and encrypted transactions through a secure payment gateway.",
  },
  {
    icon: mdiStar,
    title: " Product Reviews by Judge.me",
    desc: "Access comprehensive product reviews directly within Shopify, saving time and effort.",
  },
];

export const FeatureSection = () => {
  const gridContainerRef = useRef(null);
  const headTitleRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.animation = "slideInFromLeft 1s ease-out forwards";
        } else {
          entry.target.style.animation = "";
        }
      });
    });

    if (gridContainerRef.current) {
      observer.observe(gridContainerRef.current);
    }

    return () => {
      if (gridContainerRef.current) {
        observer.unobserve(gridContainerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.animation =
            "slideInFromRight 1s ease-out forwards";
        } else {
          entry.target.style.animation = "";
        }
      });
    });

    if (headTitleRef.current) {
      observer.observe(headTitleRef.current);
    }

    return () => {
      if (headTitleRef.current) {
        observer.unobserve(headTitleRef.current);
      }
    };
  }, []);

  return (
    <>
      <Box>
        <Container maxWidth="xl">
          <Box
            sx={{
             

              p: { xs: "10px", sm: "20px", md: "50px" }, // Responsive padding
            }}
          >
            <Box className="head-title" ref={headTitleRef}>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "24px", sm: "32px", md: "60px" }, // Responsive font size
                  fontWeight: "600",
                  textAlign:"center"
                  
                }}
              >
                Features that blows mind.
                
              </Typography>
              <Typography
                sx={{
                  width: { xs: "100%" }, // Responsive width
                  mt: "20px",
                   textAlign:"center"
                }}
              >
                Some of the features and advantages that we provide for those of
                you who store data in this Data Warehouse.
              </Typography>
            </Box>
            <Grid
              container
              className="grid-container"
              ref={gridContainerRef}
              sx={{
                gap: "20px",
                mt: "40px",
              }}
            >
              {data.map((item, index) => (
                <Grid
                  item
                  className="grid-item"
                  key={index}
                  sx={{
                    width: { xs: "100%", sm: "100%", md: "300px" }, // Responsive width
                    height: "auto",
                    // border: "1px solid #AFAFAF",
                    p: "50px 20px 30px 20px",
                    borderBottomRightRadius: "60px", // Add this line
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)", // Add this line for box shadow
                    transition:
                      "transform 0.3s ease, background-color 0.3s ease", // Smooth transition for hover effects
                    "&:hover": {
                      // backgroundColor: "#f0f0f0", // Change background color on hover
                      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                      transform: "scale(1.05)",
                      // Scale up slightly on hover
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: "60px",
                      height: "60px",
                      display: "grid",
                      placeItems: "center",
                      backgroundColor: "#1e5afa",
                      borderRadius: "50%",
                      color: "#fff",
                    }}
                  >
                    <Icon path={item.icon} size={1.5} />
                  </Box>

                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: "700",
                      marginTop: "20px",
                      lineHeight: "28px",
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#2c2c2c",
                      lineHeight: "20px",
                      marginTop: "20px",
                    }}
                  >
                    {item.desc}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};
