import React, { useEffect, useRef } from "react";
import { Box, Typography, Grid } from "@mui/material";
import formtop from "../../assests/formtopimg.png";
import formbottom from "../../assests/formbottimg.png";
import formellipse from "../../assests/formellipse.png";
import "./form-section.css";
import GetInTouch from "../Forms/GetInTouch";

const hideiten = {
    display: 'none'
}

const FormSection = () => {
  const formLeftText = useRef(null);
  const formComponent = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.animation = "slideInFromLeft 1s ease-out forwards";
        } else {
          entry.target.style.animation = "";
        }
      });
    });

    if (formLeftText.current) {
      observer.observe(formLeftText.current);
    }

    return () => {
      if (formLeftText.current) {
        observer.unobserve(formLeftText.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.animation =
            "slideInFromRight 1s ease-out forwards";
        } else {
          entry.target.style.animation = "";
        }
      });
    });

    if (formComponent.current) {
      observer.observe(formComponent.current);
    }

    return () => {
      if (formComponent.current) {
        observer.unobserve(formComponent.current);
      }
    };
  }, []);

  return (
    <Grid
      container
      sx={{ bgcolor: "#462df4", margin: "160px 0px 90px", minHeight: "380px" }}
    >
      <Grid item xs={12} md={6}>
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={12}>
            <img
              src={formtop}
              alt="topimage"
              className="heart-beat-top"
              style={{ height: "auto", width: "80px" }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            ref={formLeftText}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography
              className="text"
              sx={{
                color: "#fff",
                fontSize: { xs: "36px", md: "42px", lg: "56px" },
                fontWeight: 600,
                lineHeight: 1.1,
                marginLeft: { xs: "50px", sm: "110px" },
                marginRight: "20px",
              }}
            >
              We are here to help you grow your business
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", alignItems: "end" }}>
            <img
              className="heart-beat-bottom"
              src={formbottom}
              alt="bottomimage"
              style={{ height: "auto", width: "80px" }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ display: "flex", justifyContent: "center", paddingY: "80px" }}
      >
        <Box
          ref={formComponent}
          className="formbox"
          sx={{
            // height: "400px",
            width: { xs: "480px", sm: "500px" },
            marginTop: { xs: "0px", md: "-150px" },
            position: "relative",
            bgcolor: "#f6f9ff",
            backgroundImage:
              "linear-gradient(to left top, rgb(70,45,244) 6%, transparent 6%)",
          }}
        >
          <img
            src={formellipse}
            alt="ellipse"
            style={{
              position: "absolute",
              top: -32,
              right: -32,
              height: "auto",
              width: "70px",
            }}
          />
          <Grid item sx={{padding: '40px'}}>
            <GetInTouch hideiten={hideiten} />
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FormSection;
