

import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { mdiStar } from '@mdi/js';
import Icon from '@mdi/react';

const TrustedGrowing = () => {
  return (
    <>
      {/* First Box */}
      <Box
        padding={{ xs: "20px", md: "70px" }}
        sx={{
          textAlign: "center",
        }}
      >
        <Container maxWidth="xl">
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: "30px", md: "90px" },
              textTransform: "capitalize",
              letterSpacing: '3px',
              fontWeight: "600",
            }}
          >
            Trusted by Growing<br /> Ecommerce Businesses
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: "16px", md: "25px" },
              textTransform: "capitalize",
              color: "gray",
              marginTop: { xs: "20px", md: "40px" },
            }}
          >
            We unearth and resolve ailing problems to increase mobile conversions for Shopify Business.
          </Typography>
        </Container>
      </Box>

      {/* Second Box
      <Box
  padding={{ xs: "20px", md: "70px" }}
  sx={{
    textAlign: "center",
    backgroundColor: "#FFF3F3",
    borderTop: "2px solid #FFD1DF",
   
    marginTop: "20px", // Add margin to separate the two boxes
  }}
> */}
  {/* <Container maxWidth="xl">
    
    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
      {[...Array(5)].map((_, index) => (
        <Icon key={index} path={mdiStar} size={2} color='Gold' />
      ))}
    </div>


    <Typography
      variant="body1"
      sx={{
        fontSize: { xs: "16px", md: "29px" },
        lineHeight: { xs: "24px", md: "50px" },
        letterSpacing: '1px',
        textAlign: 'center',
      }}
    >
      
We encountered challenges with a malfunctioning mobile app created by a renowned mobile app builder. No other company matched the same level of features, affordability, and speed to market as AppCraftly, making it the ideal choice for our mobile app development needs Appcraftly.
    </Typography>

   
    <Typography
      variant="h6"
      sx={{
        fontSize: { xs: "14px", md: "20px" },
        textTransform: "capitalize",
        color: "gray",
        letterSpacing: '1px',
        marginTop: "20px",
      }}
    >

Founder, A Prominent Apparel and Accessories Boutique in Yemen
    </Typography>
  </Container> */}
{/* </Box> */}

          </>
  );
};

export default TrustedGrowing;
