import { Box, Modal } from "@mui/material";
import React from "react";
import GetInTouch from "../Forms/GetInTouch";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  width: { xs: '80%', sm: '70%', md: '60%', lg: '50%', xl: '40%' },
};

const Model = ({open, modalClose }) => {
  return (
    <Modal open={open} onClose={modalClose}>
      <Box sx={style}>
            <GetInTouch onClose={modalClose} />
      </Box>
    </Modal>
  );
};

export default Model;
