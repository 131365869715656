import * as React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import ToggleColorMode from "../ToggleColorMode/ToggleColorMode";
import Model from "../Model";
import { Link } from "react-router-dom";
import Logo from "../../assests/appcraftly.png";
import { Container } from "@mui/system";
 
function Header({ mode, toggleColorMode }) {
  const [open, setOpen] = React.useState(false);

  //
  const [openModel, setOpenModel] = React.useState(false);

  const handleOpenModel = () => {
    window.open("https://calendly.com/appcraftly/30min", "_blank");
  };

  const handleCloseModel = () => {
    setOpenModel(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };


  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: targetScroll,
        behavior: "smooth",
      });
      setOpen(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Box sx={{ bgcolor: "#1E5AFA" }}>
        <Container maxWidth="xl">
          <AppBar
            position="static"
            sx={{
              zIndex: 2,
              boxShadow: 0,
              width: "100%", // Make the header cover full width
            }}
          >
            <Toolbar
              variant="regular"
              sx={(theme) => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexShrink: 0,
                paddingTop: "30px",
                paddingBottom: "30px",
                backgroundColor: "#1E5AFA",
                maxHeight: 100,
              })}
            >
              <Box position="relative" zIndex="2">
                <Link to="/">
                  <img
                    src={Logo}
                    alt="log of appcraftly"
                    style={{
                      width: "150px",
                      height: "auto",
                      cursor: "pointer",
                    }}
                  />
                </Link>
              </Box>
              <Box
                sx={{
                  position: "relative",
                  zIndex: "9",
                  flexGrow: 1,
                  display: { xs: "none", md: "flex", paddingLeft: "100px" },
                }}
              >
                <MenuItem  
                 component={Link}
                    to="/"
                  onClick={() => scrollToSection("features")}
                  sx={{ py: "6px", px: "12px" }}
                >
                 
                    Home
                 
                </MenuItem>
                <MenuItem
                 component={Link}
                 to="/Features"
                  onClick={() => scrollToSection("testimonials")}
                  sx={{ py: "6px", px: "12px" }}
                >
                
                    Features
                 
                </MenuItem>
                <MenuItem
                 component={Link}
                 to="/Pricing"
                  onClick={() => scrollToSection("highlights")}
                  sx={{ py: "6px", px: "12px" }}
                >
               
                    Pricing
                  
                </MenuItem>
                <MenuItem
                 component={Link}
                 to="/contact"
                  onClick={() => scrollToSection("highlights")}
                  sx={{ py: "6px", px: "12px" }}
                >
              
                    Contact
                 
                </MenuItem>
              </Box>

              <Box>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{
                    position: "relative",
                    zIndex: "999",

                    fontWeight: "600",

                    display: { xs: "none", md: "flex" },

                    backgroundColor: "white",
                    color: "black",
                    "&:hover": {
                      bgcolor: "black",
                      color: "white",
                    },
                  }}
                  onClick={() => handleOpenModel()}

                // href="/material-ui/getting-started/templates/sign-up/"
                >
                  Book a demo
                </Button>
              </Box>
              <Box sx={{ display: { sm: "", md: "none" } }}>
                <Button
                  variant="text"
                  color="primary"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                  sx={{ minWidth: "30px", marginTop: '0px' }}
                >
                  <MenuIcon sx={{ color: "white", fontSize: "2rem" }} />
                </Button>
                <Drawer
                  anchor="right"
                  open={open}
                  onClose={toggleDrawer(false)}
                >
                  <Box
                    sx={{
                      minWidth: "60dvw",
                      p: 2,
                      backgroundColor: "background.paper",
                      flexGrow: 1,
                     
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end",
                        flexGrow: 1,
                       
                      }}
                    ></Box>
                    <MenuItem component={Link}
                      to="/" sx={{ marginTop: "10px" }} onClick={() => scrollToSection("features")}>

                      Home
                    </MenuItem>
                    <MenuItem component={Link} to="/Features"sx={{ marginTop: "10px" }} onClick={() => scrollToSection("testimonials")}>
                     
                        Features
               
                    </MenuItem>
                    <MenuItem component={Link}  to="/Pricing" sx={{ marginTop: "10px" }} onClick={() => scrollToSection("highlights")}>
                  
                        Pricing
                      
                    </MenuItem>

                    <MenuItem  component={Link}  to="/contact" sx={{ marginTop: "10px" }} onClick={() => scrollToSection("features")}>
                   
                       
                        Contact
                     
                    </MenuItem>
                    <Divider />

                    <MenuItem sx={{ marginTop: "20px" }}>
                      <Button
                        color="primary"
                        onClick={() => handleOpenModel()}
                        variant="outlined"
                        component="a"
                        target="_blank"
                        sx={{ width: "100%" }}
                      >
                        Get In Touch
                      </Button>
                    </MenuItem>
                  </Box>
                </Drawer>
              </Box>

            </Toolbar>
          </AppBar>
          {openModel && (
            <Model modalClose={handleCloseModel} open={handleOpenModel} />
          )}
        </Container>
      </Box>
    </>
  );
}

Header.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default Header;
