import React from 'react'
import MustHaveFeature from '../../components/MustHave-Feature/index '
import SuperCharge from '../../components/Super-charge'
import FeatureGrid from '../../components/Feature-Grid'
import SimpleTrick from '../../components/Simple-Trick'
import AccelerateUser from '../../components/Accelerate-user'
import ImgPage from '../../components/Img-Page'
import TrustedGrowing from '../../components/Trusted-Growing'
import { FeatureSection } from '../../components/FeatureSection'
import Questions from '../../components/Questions'
const index = () => {
  return (
    <div>
        {/* <MustHaveFeature/>
        <SuperCharge/> */}
        <FeatureSection/>
        {/* <Questions/> */}
        {/* <SimpleTrick/>
        <AccelerateUser/>
        <ImgPage/> */}
        {/* <TrustedGrowing/> */}
      
 
    </div>
  )
}

export default index