import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import ContactForm from "../../components/contact-form";

const ContactPage = () => {
  return (
    <Box>
      <Container maxWidth="xl">
        <Grid container sx={{ mt: 15, mb: 15 }}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "24px", sm: "32px", md: "60px" }, // Responsive font size
                  fontWeight: "600",
                }}
              >
                Contact Us
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  width: { xs: "100%", sm: "400px" }, // Responsive width
                  mt: "20px",
                }}
              >
                Fill out the form or reach us in any of the ways listed below.
                We'll be in touch soon!
              </Typography>
              <Typography sx={{ marginTop: "20px", fontWeight: "700" }}>
                Email: appcraftly@gmail.com
              </Typography>
              <Typography
                sx={{ marginTop: "5px", textTransform: "capitalize" }}
              >
                <span class="highlight" style={{ fontWeight: "700" }}>
                  Address:
                </span>{" "}
                MP nagar Bhopal, India
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Box className="accodion_layout_left">
              <ContactForm />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ContactPage;
