import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Pricing from "./view/Pricing";
import Home from "./view/Home";
import ContactPage from "./view/ContactPage";
import Features from "./view/Features";
import About from "./view/About";
import TermOfService from "./view/TermOfService";
import Privacycontent from "./view/PrivacyContent";
import FormSection from "./components/FormSection";
import { FeatureSection } from "./components/FeatureSection";

const App = () => {
  return (
    <Router>
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Header />
        <div style={{ flex: 1 }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/Features" element={<Features />} />
            <Route path="/About" element={<About />} />
            <Route path="/TermOfService" element={<TermOfService />} />
            {/* <Route path="/form" element={<FormSection />} /> */}
            <Route path="/PrivacyContent" element={<Privacycontent />} />
            {/* <Route path="/featureSection" element={<FeatureSection />} /> */}
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
