import React from "react";
import Page1 from "../../components/Page1";
import Page2 from "../../components/Page2";
import Page3 from "../../components/Page3";
import TrustedGrowing from "../../components/Trusted-Growing";
import WhyOurSolution from "../../components/WhyOurSolution";
import WhyMobileApp from "../../components/WhyMobileApp";
import AppOverWebsite from "../../components/AppOverWebsite";
import Buttondowload from "../../components/Buttondowload/Index";
import { FeatureSection } from "../../components/FeatureSection";
import FormSection from "../../components/FormSection";

const Home = () => {
  return (
    <div>
      <Page1 />
      <Buttondowload />

      <FeatureSection />
      <FormSection />
      <Page2 />

      <Page3 />
      {/* <WhyOurSolution />
      <WhyMobileApp />
      <AppOverWebsite /> */}
      <TrustedGrowing />
    </div>
  );
};

export default Home;
