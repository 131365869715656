import { Box, Container, Typography, Button, Grid } from "@mui/material";
import React from "react";


import Model from '../Model';
import { Link } from 'react-router-dom';

const Footer = () => {


  //
  const [openModel, setOpenModel] = React.useState(false);

  const handleOpenModel = () => {
    window.open("https://calendly.com/appcraftly/30min", "_blank");
  };

  const handleCloseModel = () => {
    setOpenModel(false);
  };




  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Box
        padding={{ xs: "20px",  }}
        sx={{
          // backgroundColor: "green",
          background: 'linear-gradient(#e1f3f5 ,#cbd1f2)',
          color: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          

          

        }}
      >
        <Container maxWidth="xl" sx={{paddingBottom:'40px'}}>
          <Typography
            sx={{
              fontSize: { xs: "30px", md: "90px" },
              textTransform: "capitalize",
              fontWeight: "600",
              color: "black",
              padding: "50px 0 0 0"
            }}
          >

            Launch Your Mobile App Within Two Weeks
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: "16px", md: "22px" },
              textTransform: "capitalize",
              color: "grey",
              marginTop: { xs: "20px", md: "40px" },
            }}
          >
            Our Team of Shopify and Mobile App Specialists Will Guide You Through Every Phase. From Shopify Configuration, App Integration, Theme Tailoring, to Crafting an App Download Strategy, and Even Planning Push Notification Campaigns.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpenModel()}
            sx={{
              width: "200px",
              height: "50px",
              borderRadius: "30px",
              marginTop: "40px",

            }}
          >
            SCHEDULE A DEMO
          </Button>
        </Container>
      </Box>


      <Box
        sx={{
          padding: { xs: "20px"},
          backgroundColor: "#001f73",
          borderTop: "1px solid #163a9d",
        }}
      >
        <Container maxWidth="xl">
          <Grid
            container
            direction="row"
            justifyContent="center"
            spacing={2}
          >
            <Grid item xs={12} sm={6} md={4}>
              <Box sx={{ flex: "1 1 auto" }}>
                <Typography sx={{ textTransform: "uppercase", color: "grey" }}>
                  product
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    textTransform: "capitalize",
                    color: "white",
                    marginTop: "15px",
                    fontWeight: "700",
                  }}
                >
                  {/* <Link to='/' onClick={scrollToTop} style={{ textDecoration: 'none', color: "white" }}>       next gen Shopify stack</Link> */}


                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    textTransform: "capitalize",
                    color: "white",
                    marginTop: "8px",
                    fontWeight: "700",
                  }}
                >
                  <Link to='/Pricing' onClick={scrollToTop} style={{ textDecoration: 'none', color: "white" }}>Pricing Plans</Link>

                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    textTransform: "capitalize",
                    color: "white",
                    marginTop: "8px",
                    fontWeight: "700",
                  }}
                >
                  <Link to='/Features' onClick={scrollToTop} style={{ textDecoration: 'none', color: "white" }}>       appcraftly stack Features</Link>

                </Typography>

              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box sx={{ flex: "1 1 auto" }}>
                <Typography sx={{ textTransform: "uppercase", color: "grey" }}>
                  company
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    textTransform: "capitalize",
                    color: "white",
                    marginTop: "15px",
                    fontWeight: "700",
                  }}
                >
                  {/* <Link to='/About' onClick={scrollToTop} style={{ textDecoration: 'none', color: "white" }}>About</Link> */}

                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    textTransform: "capitalize",
                    color: "white",
                    marginTop: "8px",
                    fontWeight: "700",
                  }}
                >
                  <Link to='/Contact' onClick={scrollToTop} style={{ textDecoration: 'none', color: "white" }}> contact us</Link>

                </Typography>

              </Box>

            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Box sx={{ flex: "1 1 auto" }}>
                <Typography sx={{ textTransform: "uppercase", color: "grey" }}>
                  Legal
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    textTransform: "capitalize",
                    color: "white",
                    marginTop: "15px",
                    fontWeight: "700",
                  }}
                ><Link to='/TermOfService' onClick={scrollToTop} style={{ textDecoration: 'none', color: "white" }}> service agreement</Link>

                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    textTransform: "capitalize",
                    color: "white",
                    marginTop: "8px",
                    fontWeight: "700",
                  }}
                >
                  <Link to='/PrivacyContent' onClick={scrollToTop} style={{ textDecoration: 'none', color: "white" }}>        privacy policy</Link>

                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Rest of your content */}
      <Box
        sx={{
          padding: { xs: "20px" },
          backgroundColor: "#001f73",
          borderTop: "1px solid #163a9d",
        }}
      >
        <Container maxWidth="xl">
          <Grid
            container
            direction="row"
            justifyContent="center"
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12} md={4}>
              <Typography sx={{ textTransform: "uppercase", color: "grey" }}>
                enquiries
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: "white", marginTop: "9px", fontWeight: "500" }}
              >
                appcraftly@gmail.com
              </Typography>
            </Grid>
            {/* <Grid item xs={12} md={4}>
              <Typography sx={{ textTransform: "uppercase", color: "grey" }}>
                address
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  textTransform: "capitalize",
                  color: "white",
                  marginTop: "9px",
                  fontWeight: "500",
                }}
              >
                Bhopal, India
              </Typography>
            </Grid> */}
            {/* <Grid item xs={12} md={4}>
              <Typography sx={{ textTransform: "uppercase", color: "grey" }}>
                follow us
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  textTransform: "capitalize",
                  color: "white",
                  marginTop: "9px",
                  fontWeight: "500",
                }}
              ></Typography>
            </Grid> */}
          </Grid>
        </Container>
      </Box>

      <Box
        component="footer"
        sx={{
          backgroundColor: "#001f73",
          py: 2,
          px: 4,
          textAlign: "center",
          borderTop: "1px solid #163a9d",
          mt: "auto", // Pushes the footer to the bottom of the page
        }}
      >
        <Container maxWidth="xl">
          <Grid container direction="row" justifyContent="center">
            <Typography variant="body2" color="grey">
              © {new Date().getFullYear()} appcraftly&nbsp;&nbsp;&nbsp;
            </Typography>
            <Typography variant="body2" color="grey">
              <Link to='/PrivacyContent' onClick={scrollToTop} style={{ textDecoration: 'none', color: "grey" }}> Privacy Policy</Link>  {" | "} <Link to='/TermOfService' onClick={scrollToTop} style={{ textDecoration: 'none', color: "grey" }}>Terms of Service</Link>
            </Typography>
          </Grid>
        </Container>
        {openModel && (
          <Model modalClose={handleCloseModel} open={handleOpenModel} />
        )}
      </Box>
    </>
  );
};

export default Footer;
