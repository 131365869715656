// import {
//   Accordion,
//   AccordionDetails,
//   AccordionSummary,
//   Avatar,
//   Box,
//   Button,
//   Container,
//   Grid,
//   Typography,
// } from "@mui/material";
// import DiscountIcon from '@mui/icons-material/Discount';
// import ShareIcon from '@mui/icons-material/Share';
// import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
// import ListIcon from '@mui/icons-material/List';
// import FolderCopyIcon from '@mui/icons-material/FolderCopy';
// import React, { useEffect, useRef } from "react";
// import mobileimg from "../../assests/download23.png";
// import playstore from "../../assests/app_btn1.png";
// import downlaod from "../../assests/app_btn2.png";
// import "./mobile.css"; // Import your CSS file for animations

// const Page2 = () => {
//   const [expanded, setExpanded] = React.useState("panel1");
//   const gridContainerRef = useRef(null);
//   const headTitleRef = useRef(null);

// useEffect(() => {
//   const observerLeft = new IntersectionObserver((entries) => {
//     entries.forEach((entry) => {
//       if (entry.isIntersecting) {
//         entry.target.style.animation = "slideInFromLeft 1s ease-out forwards";
//       } else {
//         entry.target.style.animation = "";
//       }
//     });
//   });

//   if (gridContainerRef.current) {
//     observerLeft.observe(gridContainerRef.current);
//   }

//   return () => {
//     if (gridContainerRef.current) {
//       observerLeft.unobserve(gridContainerRef.current);
//     }
//   };
// }, []);

// useEffect(() => {
//   const observerRight = new IntersectionObserver((entries) => {
//     entries.forEach((entry) => {
//       if (entry.isIntersecting) {
//         entry.target.style.animation = "slideInFromRight 1s ease-out forwards";
//       } else {
//         entry.target.style.animation = "";
//       }
//     });
//   });

//   if (headTitleRef.current) {
//     observerRight.observe(headTitleRef.current);
//   }

//   return () => {
//     if (headTitleRef.current) {
//       observerRight.unobserve(headTitleRef.current);
//     }
//   };
// }, []);

//   const handleChange = (panel) => (event, newExpanded) => {
//     setExpanded(newExpanded ? panel : false);
//   };

//   const panelColors = {
//     1: "blue",
//     2: "blue",
//     3: "blue",
//     4: "blue",
//     5: "blue",
//     6: "blue",
//     7: "blue",
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       const leftElement = document.querySelector('#page2 .unique-class-left');
//       const rightElements = document.querySelectorAll('#page2 .unique-class-right');

//       const scrollPosition = window.scrollY;

//       const leftTrigger = window.innerHeight * 0.6; // 60% of the viewport height
//       const rightTrigger = window.innerHeight * 0.4; // 40% of the viewport height

//       if (scrollPosition > leftTrigger) {
//         leftElement.classList.add('myclasses1');
//       }

//       rightElements.forEach((element) => {
//         if (scrollPosition > rightTrigger) {
//           element.classList.add('myclasses2');
//         }
//       });
//     };

//     window.addEventListener('scroll', handleScroll);

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   return (
//     <Container id="page2" maxWidth="xl">
//       <Typography ref={headTitleRef} variant="h4" sx={{ fontSize: { xs: "22px", md: "35px", textAlign: "center", marginRight: "50px" }, fontWeight: '400', letterSpacing: '2px', marginBottom: '20px' }}>
//         Boost Your Sales with Mobile App
//       </Typography>
//       <Grid ref={gridContainerRef} container justifyContent="center" alignItems="center" spacing={2} sx={{ mt: 4 }}>
//         <Grid item xs={12} md={6}>
//           <Box className={`unique-class-left`} sx={{ textAlign: 'center', marginBottom: '20px' }}>
//             <img
//               style={{ width: "80%", height: "auto" }} // Adjusted image size
//               src={mobileimg}
//               alt="Modern Technology"
//             />
//             <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
//               <Button
//                 href="https://www.apple.com/in/app-store/">
//                 <img src={playstore} alt="playstore" />
//               </Button>
//               <Button
//                 href="https://play.google.com/store/games?hl=en&gl=US">
//                 <img src={downlaod} alt="downlaod" />
//               </Button>
//             </Box>
//           </Box>
//         </Grid>
//         <Grid item xs={12} md={6} className={`unique-class-right`}>
//           {[1, 2, 3, 4, 5, 6, 7].map((panel) => (
//             <Accordion
//               key={`panel-${panel}`}
//               expanded={expanded === `panel${panel}`}
//               onChange={handleChange(`panel${panel}`)}
//               sx={{
//                 boxShadow: "none",
//                 marginBottom: "10px",
//               }}
//             >
//               <AccordionSummary
//                 aria-controls={`panel${panel}d-content`}
//                 id={`panel${panel}d-header`}
//               >
//                 <Typography
//                   variant="subtitle1"
//                   sx={{
//                     display: "flex",
//                     alignItems: "center",
//                     fontFamily: "unset",
//                   }}
//                 >
//                   <Avatar
//                     className={`panelAvatar unique-class-${panel}`}
//                     sx={{
//                       backgroundColor: panelColors[panel],
//                       width: "60px",
//                       height: "60px",
//                       display: "grid",
//                       placeItems: "center",
//                       borderRadius: "50%",
//                       color: "#fff",
//                       marginRight: '20px'
//                     }} // Adjusted avatar size
//                   >
//                     <span id={`panel-icon-${panel}`} key={`panel-${panel}`}>
//                       {panel === 1 ? (
//                         <DiscountIcon />
//                       ) : panel === 2 ? (
//                         <PhoneAndroidIcon />
//                       ) : panel === 3 ? (
//                         <DiscountIcon />
//                       ) : panel === 4 ? (
//                         <ListIcon />
//                       ) : panel === 5 ? (
//                         <DiscountIcon />
//                       ) : panel === 6 ? (
//                         <ShareIcon />
//                       ) : panel === 7 ? (
//                         <FolderCopyIcon />
//                       ) : null}
//                     </span>
//                   </Avatar>
//                   <Typography
//                     variant="h6"
//                     sx={{ display: "flex", fontFamily: "unset" }}
//                   >
//                     {panel === 1
//                       ? "Smooth Transition"
//                       : panel === 2
//                         ? "Optimal performance"
//                         : panel === 3
//                           ? "Enhanced integration"
//                           : panel === 4
//                             ? "Advanced functionalities"
//                             : panel === 5
//                               ? "Dedicated support"
//                               : panel === 6
//                                 ? "Competitive advantage"
//                                 : "PROVEN TRACK RECORD"}
//                   </Typography>
//                 </Typography>
//               </AccordionSummary>
//               <AccordionDetails>
//                 <Typography variant="body2">
//                   {panel === 1
//                     ? " Seamlessly transform your Shopify website into native Android/iOS app. Not a DIY solution."
//                     : panel === 2
//                       ? "Ensure superior app performance and user experience compared to other app builders"
//                       : panel === 3
//                         ? "Integrate your Shopify store into native apps, preserving brand identity and product catalog."
//                         : panel === 4
//                           ? "Enjoy features like push notifications, offline access, seamless checkout process and so much more to enhance user engagement and drive conversions."
//                           : panel === 5
//                             ? "Benefit from ongoing support and guidance from our expert team, for ensuring smooth onboarding, transition and continued success."
//                             : panel === 6
//                               ? "Maintain a competitive edge by using a native app solution that has been expertly built and customized to your business specific requirements."
//                               : "Demonstrated success and reliability."}
//                 </Typography>
//               </AccordionDetails>
//             </Accordion>
//           ))}
//         </Grid>
//       </Grid>
//     </Container>
//   );
// };

// export default Page2;

import React, { useEffect, useRef } from "react";
import { Box, Grid, Typography, Container } from "@mui/material";
import "./mobile.css";
// import notification from "../../assests/notification-bell.png";
// import productWish from "../../assests/wish-list.png";
// import productFiltering from "../../assests/controls.png";
// import productSorting from "../../assests/download-file.png";
import Icon from "@mdi/react";
import {
  mdiHeartOutline,
  mdiFilterOutline,
  mdiLinkVariant,
  mdiCheckDecagramOutline,
  mdiTransition,
  mdiFunction,
} from "@mdi/js";
const data = [
  {
    icon: mdiTransition,
    title: "Smooth Transition",
    desc: " Seamlessly transform your Shopify website into native Android/iOS app. Not a DIY solution.",
  },
  {
    icon: mdiHeartOutline,
    title: "Optimal performance",
    desc: "Ensure superior app performance and user experience compared to other app builders.",
  },
  {
    icon: mdiFilterOutline,
    title: "Enhanced integration",
    desc: "Integrate your Shopify store into native apps, preserving brand identity and product catalog.",
  },
  {
    icon: mdiFunction,
    title: "Advanced functionalities",
    desc: "Enjoy features like push notifications, offline access, seamless checkout process and so much more to enhance user engagement and drive conversions.",
  },
  {
    icon: mdiLinkVariant,
    title: "Dedicated support",
    desc: "Benefit from ongoing support and guidance from our expert team, for ensuring smooth onboarding, transition and continued success.",
  },
  {
    icon: mdiCheckDecagramOutline,
    title: "Competitive advantage",
    desc: "Maintain a competitive edge by using a native app solution that has been expertly built and customized to your business specific requirements.",
  },
];

const Page2 = () => {
  const gridContainerRef = useRef(null);
  const headTitleRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.animation = "slideInFromLeft 1s ease-out forwards";
        } else {
          entry.target.style.animation = "";
        }
      });
    });

    if (gridContainerRef.current) {
      observer.observe(gridContainerRef.current);
    }

    return () => {
      if (gridContainerRef.current) {
        observer.unobserve(gridContainerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.animation =
            "slideInFromRight 1s ease-out forwards";
        } else {
          entry.target.style.animation = "";
        }
      });
    });

    if (headTitleRef.current) {
      observer.observe(headTitleRef.current);
    }

    return () => {
      if (headTitleRef.current) {
        observer.unobserve(headTitleRef.current);
      }
    };
  }, []);

  return (
    <>
      <>
        <Grid>
          <Container maxWidth="xl">
            <Box
              sx={{
                p: { xs: "10px", sm: "20px", md: "50px" }, // Responsive padding
              }}
            >
              <Box className="head-title" ref={headTitleRef}>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { xs: "24px", sm: "32px", md: "60px" }, // Responsive font size
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Boost Your Sales with Mobile App
                </Typography>
                {/* <Typography
                sx={{
                  width: { xs: "100%", sm: "400px" }, // Responsive width
                  mt: "20px",
                }}
              >
                Some of the features and advantages that we provide for those of
                you who store data in this Data Warehouse.
              </Typography> */}
              </Box>
              <Box
                // className="grid-container"
                ref={gridContainerRef}
                sx={{
                  // gap: "20px",
                  mt: "40px",
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  gridTemplateColumns: { xs: "1fr", sm: "repeat(3, 1fr)" }, // Three columns on small screens and above
                  gridTemplateRows: "repeat(2, auto)", // Two rows
                  "--Grid-borderWidth": "1px",
                      borderTop: "var(--Grid-borderWidth) solid",
                      borderLeft: "var(--Grid-borderWidth) solid",
                      borderColor: "divider",
                      "& > div": {
                        borderRight: "var(--Grid-borderWidth) solid",
                        borderBottom: "var(--Grid-borderWidth) solid",
                        borderColor: "divider",
                      },
                }}
              >
                {data.map((item, index) => (
                  <Box
                    item
                    className="grid-item"
                    key={index}
                    sx={{
                      width: { xs: "100%", sm: "100%", md: "30.1%" }, // Responsive width
                      height: "auto",
                      // background: "#e1f3f5",
                      p: "50px 20px 30px 20px",
                      
                      // borderBottomRightRadius: "60px",
                      // boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                      transition:
                        "transform 0.3s ease, background-color 0.3s ease",
                      "&:hover": {
                        // boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                        // transform: "scale(1.05)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: "60px",
                        height: "60px",
                        display: "grid",
                        placeItems: "center",
                        backgroundColor: "#1e5afa",
                        borderRadius: "50%",
                        color: "#fff",
                      }}
                    >
                      <Icon path={item.icon} size={1.5} />
                    </Box>

                    <Typography
                      sx={{
                        fontSize: "24px",
                        fontWeight: "700",
                        marginTop: "20px",
                        lineHeight: "28px",
                        // color:"white"
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#2c2c2c",
                        lineHeight: "20px",
                        marginTop: "20px",
                        // color:"white"
                      }}
                    >
                      {item.desc}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Container>
        </Grid>
      </>
    </>
  );
};

export default Page2;
