// import React from 'react'
// import {Box ,Container ,Button ,Grid} from '@mui/material'
// import playstore from "../../assests/app_btn1.png";
// import downlaod from "../../assests/app_btn2.png";
// const Buttondowload = () => {
//   return (
// <Box  maxWidth='xl'  >
//     <Container maxWidth="xl" sx={{display:"flex" , justifyContent:'center' ,  }} >
//         <Grid Container  sx={{display:"flex" , marginTop:'30px'  }}>
//         <Grid   ><Button
//                 href="https://www.apple.com/in/app-store/">
//                 <img src={playstore} alt="playstore" />
//               </Button>
//              </Grid>
//                       <Grid> <Button
//                 href="https://play.google.com/store/games?hl=en&gl=US">
//                 <img src={downlaod} alt="downlaod" />
//               </Button></Grid>
//                   </Grid>
//     </Container>

// </Box>

//   )
// }

// export default Buttondowload


import React from 'react';
import { Box, Container, Button, Grid, styled } from '@mui/material';
import playstore from "../../assests/app_btn1.png";
import downlaod from "../../assests/app_btn2.png";

// Styled Button component with hover effect and animation
const AnimatedButton = styled(Button)({
  transition: 'transform 0.3s ease', // Add a transition effect
  '&:hover': {
    transform: 'scale(1.1)', // Increase scale on hover
  },
});

const Buttondowload = () => {
  return (
    <>
      <Box>
        <Container maxWidth="xl" >
          <Grid container sx={{ display: "flex", justifyContent: "center", marginBottom: { xs: '30px', md: '' } }} >
            <Grid item>
              {/* Use AnimatedButton component */}
              <AnimatedButton href="https://apps.apple.com/bs/app/fashion-market/id6472798007" target="_blank">
                <img src={playstore} alt="playstore" />
              </AnimatedButton>

            </Grid>
            <Grid item>
              {/* Use AnimatedButton component */}
              {/* <AnimatedButton href="https://play.google.com/store/games?hl=en&gl=US">
              <img src={downlaod} alt="downlaod" />
            </AnimatedButton> */}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default Buttondowload;
