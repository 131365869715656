import React from 'react';
import { Container, Grid, Typography, List, ListItem } from "@mui/material";

const AboutUs = () => {
  return (
    <Container maxWidth="xl">
      <Grid container spacing={2} justifyContent="center" alignItems="center" marginTop='110px' marginBottom='90px'>
        <Grid item xs={12} md={6}>
          <Typography variant='h6' sx={{ fontSize: { xs: '40px', md: '70px' }, fontWeight: '700', textAlign: 'center' }}>About us</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <List>
            <ListItem>
              <Typography variant='h5'>We are a Shopify partner company.</Typography>
            </ListItem>
            <ListItem>
              <Typography variant='h5'>We are platform agnostic for integrations.</Typography>
            </ListItem>
            <ListItem>
              <Typography variant='h5'>One-stop solution for better engagement & sales on your store.</Typography>
            </ListItem>
            <ListItem>
              <Typography variant='h5'>We offer tailored solutions as per the need of your ecommerce store.</Typography>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    
    </Container>
  );
};

export default AboutUs;
