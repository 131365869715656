import { apiEndPointsConfig } from "./apiEndPointsConfig";
import { axiosInstance } from "./axiosInstance";

// export const testConnection = async (data) => {
//   try {
//     const res = await axiosInstance.post(
//       apiEndPointsConfig.testConnection,
//       data
//     );
//     console.log("Test Connection");
//     return res;
//   } catch (err) {
//     return err;
//   }
// };






 // Assuming you have a configuration file

 export const createGetInTouch = async (data) => {
  const params = new URLSearchParams(window.location.search);
  const utmSource = params.get('utm_source') || 'Genuine';
  const utmMedium = params.get('utm_medium') || 'Genuine';
  const utmCampaign = params.get('utm_campaign') || 'Genuine';

  data.utm_source = utmSource;
  data.utm_medium = utmMedium;
  data.utm_campaign = utmCampaign;

  try {
    // Send the data to the server
    const res = await axiosInstance.post(apiEndPointsConfig.saveutm, data);
    console.log("data created", res);
    return res;
  } catch (err) {
    return err;
  }
};


export  const sendEmailToAdmin = async (values) => {
  console.log(values,"value coming from data");
  const data = {
    name: values.name,
    email: values.email,
    phone: values.phone,
    text: values.text
  }
  try {
    const response = await axiosInstance.post(apiEndPointsConfig.sendmail,data);
    console.log(response.data); // Log the response from the server
    return response.data;
  } catch (error) {
    console.error('Error sending email to admin:', error);
    throw error; // Throw the error to handle it in the component
  }
};



// get appoinments
// export const getAllAppoinments = async () => {
//   try {
//     const res = await axiosInstance.get(`${apiEndPointsConfig.getAppoinments}`);
//     const result = res.data.data.map((item) => {
//       item.slot_dates = item.slot_dates.split('T')[0];
//       return item;
//     });
//     console.log("get all appoinments", result);
//     return result;
//   } catch (err) {
//     return err;
//   }
// };


// export const saveutmdata = async (utmdata) => {
//   try {
//     const res = await axiosInstance.post(
//       apiEndPointsConfig.saveutm,
//       utmdata
//     );
   
//     return res;
//   } catch (err) {
//     return err;
//   }
// };


