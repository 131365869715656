import React from 'react'
import { Container, Box, Typography, Grid } from '@mui/material'
import Icon from '@mdi/react';
import { mdiEmoticon } from '@mdi/js';
function Page3() {
  return (
    <Box  sx={{ background: 'linear-gradient(#e1f3f5 ,#cbd1f2)', padding:{xs:"15px"}}}>
      <Box  sx={{
        display: 'flex',

        marginTop: "50px",
        marginBottom: '50px',
        justifyContent: 'center'
      }}>
        <Box sx={{
         
        }}>
          <Box maxWidth="xl" sx={{
            // backgroundColor: 'rgba(255, 255, 255, 0.15)',

            // padding: { xs: '30px', md: '100px', lg: '100px' },
            // boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
            // backdropFilter: 'blur(10px)',
            // border: '1px solid rgba(255, 255, 255, 0.18)',
            height: 'auto', // Adjusted height for mobile view
            // Added margin top for spacing, // Adjust opacity as needed
            
          }}>
            <Typography sx={{ fontSize: { xs: '20px', md: '30px', sm:"35px" }, color: 'black', textAlign: 'left', lineHeight: { xs: '', md: '60px', lg: '55px' }, variant: 'h1', fontWeight: '400' }}  >Skip the DIY headaches !  We're not your run-of-the-mill app builder Our approach is personalized to your needs  offering seamless result without the hassle of DIY
            </Typography>
          </Box>
        </Box>
      </Box>

    </Box>
  )
}

export default Page3
