// import {
//   Grid,
//   Box,
//   Typography,
//   TextField,
//   Button,
//   FormHelperText,
 
// } from "@mui/material";
// import { useFormik } from "formik";
// import * as yup from "yup";
// import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// import { createGetInTouch, sendEmailToAdmin } from "../../services";
// import { useState } from "react";

// const GetInTouch = ({ onClose }) => {
//   const [focused, setFocused] = useState(false);
//   const handleFocus = () => {
//     setFocused(true);
//   };

//   const handleBlur = () => {
//     setFocused(false);
//   };

//   const initialValues = {
//     name: "",
//     phone: "",
//     email: "",
//     text: "",
//   };

//   const validationSchema = yup.object().shape({
//     name: yup
//       .string()
//       .matches(/^[A-Za-z\s]*$/, "Please enter a valid name")
//       .min(3, "Name must be at least 3 characters")
//       .max(20, "Name must be at most 20 characters")
//       .required("Name is required"),
//     phone: yup
//       .number()
//       .required("Please enter number")
//       .test("is-ten-digits", "Number must be exactly 10 digits", (value) => {
//         if (value) {
//           const stringValue = String(value);
//           return stringValue.length === 10;
//         }
//         return false;
//       }),
//     email: yup
//       .string()
//       .email("Please enter a valid email address")
//       .required("Email is required"),

//     // email: yup.email().required("Email is required"),
//   });

//   const onSubmit = async (values, { resetForm }) => {
//     console.log("values", values);
//     await createGetInTouch(values);
//     await sendEmailToAdmin()
//     // alert("form submitted");
//     resetForm();
//   };

//   const formik = useFormik({
//     initialValues: initialValues,
//     validationSchema: validationSchema,
//     onSubmit: onSubmit,
//   });

//   return (
//     <Box>
//       <Box fullWidth sx={{ display: "flex", justifyContent: "center" }}>
//       <Typography
//         sx={{
//           fontSize: "30px",
//           fontWeight: "bold",
//           color: "#333",
//           flexGrow:1
//         }}
//       >
//         Get In Touch
//       </Typography>
//         {onClose && (
//           <Button
//             onClick={onClose}
//             sx={{
//               "&:hover": {
//                 backgroundColor: "transparent",
//               },
//             }}
//           >
//             <HighlightOffIcon sx={{ fontSize: "32px", color: "#787878" }} />
//           </Button>
//         )}
//       </Box>
      

//       <form onSubmit={formik.handleSubmit}>
//         <Grid container spacing={3}>
//           <Grid item xs={6}>
//             <TextField
//               id="nameField"
//               type="text"
//               variant="standard"
//               fullWidth
//               // placeholder="Name"
//               placeholder={!focused ? "Name" : ""}
//               sx={{
//                 "& input": {
//                   padding: "17px 14px",
//                 },
//                 "& .MuiFormHelperText-root": {
//                   margin: 0,
//                 },
//               }}
//               name="name"
//               value={formik.values.name}
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               error={formik.touched.name && Boolean(formik.errors.name)}
//             />
//             {formik.touched.name && formik.errors.name && (
//               <FormHelperText
//                 sx={{
//                   margin: 0,
//                   color: "red",
//                   fontSize: "12px",
//                 }}
//               >
//                 {formik.errors.name}
//               </FormHelperText>
//             )}
//           </Grid>
//           <Grid item xs={6}>
//             <TextField
//               id="numberField"
//               type="tel"
//               variant="standard"
//               fullWidth
//               placeholder="Phone"
//               sx={{
//                 "& input": {
//                   padding: "17px 14px",
//                 },
//                 "& .MuiFormHelperText-root": {
//                   margin: 0,
//                 },
//               }}
//               name="phone"
//               value={formik.values.phone}
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               error={formik.touched.phone && Boolean(formik.errors.phone)}
//             />
//             {formik.touched.phone && formik.errors.phone && (
//               <FormHelperText
//                 sx={{
//                   margin: 0,
//                   color: "red",
//                   fontSize: "12px",
//                 }}
//               >
//                 {formik.errors.phone}
//               </FormHelperText>
//             )}
//           </Grid>
//           <Grid item xs={12}>
//             <TextField
//               id="numberField"
//               type="email"
//               variant="standard"
//               fullWidth
//               placeholder="Email"
//               sx={{
//                 "& input": {
//                   padding: "17px 14px",
//                 },
//                 "& .MuiFormHelperText-root": {
//                   margin: 0,
//                 },
//               }}
//               name="email"
//               value={formik.values.email}
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               error={formik.touched.email && Boolean(formik.errors.email)}
//             />
//             {formik.touched.email && formik.errors.email && (
//               <FormHelperText
//                 sx={{
//                   margin: 0,
//                   color: "red",
//                   fontSize: "12px",
//                 }}
//               >
//                 {formik.errors.email}
//               </FormHelperText>
//             )}
//           </Grid>
//           <Grid item xs={12}>
//             <TextField
//               type="text"
//               variant="standard"
//               fullWidth
//               placeholder="Message"
//               sx={{
//                 "& input": {
//                   padding: "17px 14px",
//                 },
//                 "& .MuiFormHelperText-root": {
//                   margin: 0,
//                 },
//               }}
//               name="text"
//               value={formik.values.text}
//               onChange={formik.handleChange}
//             />
//           </Grid>
//         </Grid>
//         <Box
//           sx={{
//             mt: "60px",
//           }}
//         >
//           <Button type="submit" variant="outlined" size="large">
//             Get In Touch
//           </Button>
//         </Box>
//       </form>
//     </Box>
//   );
// };

// export default GetInTouch;

import { Grid, Box, Typography, TextField, Button, FormHelperText, Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { createGetInTouch, sendEmailToAdmin } from "../../services";
import { useState } from "react";

const GetInTouch = ({ onClose }) => {
  const [focused, setFocused] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const initialValues = {
    name: "",
    phone: "",
    email: "",
    text: "",
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .matches(/^[A-Za-z\s]*$/, "Please enter a valid name")
      .min(3, "Name must be at least 3 characters")
      .max(20, "Name must be at most 20 characters")
      .required("Name is required"),
    phone: yup
      .string()
      .matches(/^\d{10}$/, "Number must be exactly 10 digits")
      .required("Phone number is required"),
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email is required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    try {
      console.log("values", values);
      await createGetInTouch(values);
      await sendEmailToAdmin(values);
      console.log("Form submitted successfully");
      resetForm();
      setSnackbarSeverity("success");
      setSnackbarMessage("Form submitted successfully");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error submitting form:", error);
      setSnackbarSeverity("error");
      setSnackbarMessage("Error in submitting form");
      setSnackbarOpen(true);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box>
      <Box fullWidth sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: "bold",
            color: "#333",
            flexGrow: 1,
          }}
        >
          Get In Touch
        </Typography>
        {onClose && (
          <Button
            onClick={onClose}
            sx={{
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <HighlightOffIcon sx={{ fontSize: "32px", color: "#787878" }} />
          </Button>
        )}
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              id="nameField"
              type="text"
              variant="standard"
              fullWidth
              placeholder={!focused ? "Name" : ""}
              sx={{
                "& input": {
                  padding: "17px 14px",
                },
                "& .MuiFormHelperText-root": {
                  margin: 0,
                },
              }}
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
            />
            {formik.touched.name && formik.errors.name && (
              <FormHelperText
                sx={{
                  margin: 0,
                  color: "red",
                  fontSize: "12px",
                }}
              >
                {formik.errors.name}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="numberField"
              type="tel"
              variant="standard"
              fullWidth
              placeholder="Phone"
              sx={{
                "& input": {
                  padding: "17px 14px",
                },
                "& .MuiFormHelperText-root": {
                  margin: 0,
                },
              }}
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
            />
            {formik.touched.phone && formik.errors.phone && (
              <FormHelperText
                sx={{
                  margin: 0,
                  color: "red",
                  fontSize: "12px",
                }}
              >
                {formik.errors.phone}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="emailField"
              type="email"
              variant="standard"
              fullWidth
              placeholder="Email"
              sx={{
                "& input": {
                  padding: "17px 14px",
                },
                "& .MuiFormHelperText-root": {
                  margin: 0,
                },
              }}
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
            {formik.touched.email && formik.errors.email && (
              <FormHelperText
                sx={{
                  margin: 0,
                  color: "red",
                  fontSize: "12px",
                }}
              >
                {formik.errors.email}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="text"
              variant="standard"
              fullWidth
              placeholder="Message"
              sx={{
                "& input": {
                  padding: "17px 14px",
                },
                "& .MuiFormHelperText-root": {
                  margin: 0,
                },
              }}
              name="text"
              value={formik.values.text}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: "60px" }}>
          <Button type="submit" variant="outlined" size="large">
            Get In Touch
          </Button>
        </Box>
      </form>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default GetInTouch;
